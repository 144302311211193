@import url('https://fonts.googleapis.com/css2?family=Averia+Sans+Libre:wght@300;400;700&display=swap');
@import url("https://use.typekit.net/kmi6ovz.css");

body.transparent, body.transparent #root, body.transparent #root > div {
    background: none !important;
}

html {
    background: none !important;
    color-scheme: unset !important;
}